import React from "react";

import Mail from "../Icons/Mail";
import LinkedIn from "../Icons/LinkedIn";
import Twitter from "../Icons/Twitter";

import "./SocialCard.scss";

const SocialCard = props => (
	<div className="SocialCard">
		<img src={props.image} alt="mensen"/>
				<h4>{props.name}</h4>
				<p>{props.title}</p>
				<div className="SocialButtons">
					<a href={"mailto:"+ props.mail} ><Mail /></a>
					<a href={props.linkedin} target="_blank" rel="noopener noreferrer"><LinkedIn /></a>
					<a href={props.twitter} target="_blank" rel="noopener noreferrer"><Twitter /></a>
				</div>
	</div>
);

export default SocialCard;
