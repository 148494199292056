import React from "react";

import "./Bestuur.scss"

const Bestuur = () => (
	<div id="Bestuur" className="Blok">
	 <div className="Overlay"></div>
		<div className="Container">
			<h2>Bestuur</h2>

			<div className="Content">
				<p>Het bestuur van Stichting (Ont)Regel de Zorg ligt bij de initiatiefnemers achter de stichting.</p>
				<p>Namens VvAA is <a href="https://www.artsenauto.nl/vvaa-bestuurslid-jandiet-berendsen/" target="_blank" rel="noopener noreferrer">JanDiet Berendsen</a> de voorzitter van het bestuur.</p>
				<p>Namens De Argumentenfabriek vervult <a href="https://www.argumentenfabriek.nl/nl/wie-zijn-de-fabriekers/kees-kraaijeveld/" target="_blank" rel="noopener noreferrer">Kees Kraaijeveld</a> de rol van penningmeester en secretaris.</p>
			</div>

		</div>
	</div>
);

export default Bestuur;
