import React from "react";
import { Link } from "react-router-dom";

import Mail from "../Icons/Mail";
import Phone from "../Icons/Phone";
import './Footer.scss';

import Voorwaarden from "../../images/algemene-voorwaarden-stichting-ontregel-de-zorg.pdf";
import Klachten from "../../images/klachtenregeling-stichting-ontregel-de-zorg.pdf";

const Footer = () => (
	<footer className="Footer">
		<div className="Container">

				<ul>
					<li><Link to="/bestuur">Bestuur</Link></li>
					<li><a href={Voorwaarden} download>Algemene voorwaarden</a></li>
				</ul>

				<ul>
					<li><a href={Klachten} download>Klachtenregeling</a></li>
					<li><Link to="/privacy">Privacystatement</Link></li>
				</ul>

				<div>
					Vragen?
					<ul>
					<li><Mail /><a href="mailto:info@stichtingontregeldezorg.nl">info@stichtingontregeldezorg.nl</a></li>
					<li><Phone />030 247 41 82</li>
				</ul>
				</div>

			</div>
	</footer>
);

export default Footer;

