import React from "react";

import SocialCard from "../SocialCard/SocialCard";
import portretTijmen from "../../images/image-tijmen-hiep.png";
import portretJolande from "../../images/image-jolande-verhulst.png";
import portretShaun from "../../images/image-shaun-lednor.png";
import portretAnneke from "../../images/image-anneke-vant-zet.png";

import "./OverOns.scss";

const OverOns = () => (
	<div id="OverOns" className="Blok">
		<div className="Container">
			<h2>Over ons</h2>

				<div className="SocialCards">


					<SocialCard
						name="Tijmen Hiep"
						title="directeur Stichting (Ont)Regel de Zorg"
						linkedin="https://nl.linkedin.com/in/tijmen-hiep-2119044a"
						mail="info@stichtingontregeldezorg.nl"
						twitter="https://twitter.com/VvAA"
						image={portretTijmen}
					/>

					<SocialCard
						name="Jolande Verhulst"
						title="directeur Academie voor Medisch Specialisten"
						mail="jolande.verhulst@academiems.nl"
						twitter="https://twitter.com/JoVerhulst"
						linkedin="https://nl.linkedin.com/in/jolande-verhulst-13940b1"
						image={portretJolande}
					/>

					<SocialCard
						name="Shaun Lednor"
						title="chef De Denkacademie"
						mail="shaun@argumentenfabriek.nl"
						twitter="https://twitter.com/denkacademie"
						linkedin="https://nl.linkedin.com/in/shaun-lednor-b1235412"
						image={portretShaun}
					/>

					<SocialCard
						name="Anneke van 't Zet"
						title="Projectmanager (Ont)Regel de Zorg"
						mail="anneke.vant.zet@vvaa.nl"
						twitter="https://twitter.com/AnnekevantZet"
						linkedin="https://nl.linkedin.com/in/annekevantzet"
						image={portretAnneke}
					/>

				</div>

		</div>
	</div>
);

export default OverOns;
