import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import "./Contact.scss";

class Contact extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: "",
			mail: "",
			phone: "",
			infoStichting: false,
			infoTrainingen: false,
			infoSchrapsessies: false
		};
	}

	handleChange = event => {
		const fieldName = event.target.name;
		const newState = {};
		newState[fieldName] = event.target.value;
		this.setState(newState);
	};

	handleInputCheckBoxes = event => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	handleSubmit = evt => {
		evt.preventDefault();
		evt.stopPropagation();
		if (!this.state['NO-WALL-E']) return;
		const {
			name,
			mail,
			phone,
			infoStichting,
			infoTraining,
			infoSchrapsessies
		} = this.state;
		const urlApi = "https://www.stichtingontregeldezorg.nl/api";

		const topic = `
			${infoStichting ? "de Stichting (Ont)Regel de Zorg" : ""}
			${infoTraining ? "Trechtertrainingen" : ""}
			${infoSchrapsessies ? "Schrapsessies" : ""}
		`;

		fetch(urlApi, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ name, mail, phone, topic })
		})
			.then(response => response.json())
			.then(result =>
				this.setState({
					name: "",
					mail: "",
					phone: "",
					infoStichting: false,
					infoTrainingen: false,
					infoSchrapsessies: false,
					message: "Uw verzoek om informatie is verstuurd."
				})
			);
	};

	render() {

		return (
			<div id="Contact" className="Contact Blok">
				<div className="BlokOverlay">
					<div className="Container">
						<h2>Contact</h2>
						<form className="Form" onSubmit={this.handleSubmit}>
							<div className="Fields">
								<div className="UserFields">
									<input
										type="text"
										name="name"
										required
										value={this.state.name}
										onChange={this.handleChange}
										placeholder="Naam"
									/>
									<input
										type="email"
										name="mail"
										required
										value={this.state.mail}
										onChange={this.handleChange}
										placeholder="E-mail"
									/>
									<input
										type="tel"
										required
										name="phone"
										value={this.state.phone}
										onChange={this.handleChange}
										placeholder="Telefoonnummer"
									/>

								</div>
								<div className="InfoFields">
									Graag ontvang ik informatie over:
									<div className="checkbox">
										<label>
											<input
												name="infoStichting"
												type="checkbox"
												checked={this.state.infoStichting}
												onChange={this.handleInputCheckBoxes}
											/>
											De stichting (Ont)Regel de Zorg
										</label>
									</div>
									<div className="checkbox">
										<label>
											<input
												name="infoTrainingen"
												type="checkbox"
												checked={this.state.infoTrainingen}
												onChange={this.handleInputCheckBoxes}
											/>
											Ontregeltrainingen
										</label>
									</div>
									<div className="checkbox">
										<label>
											<input
												name="infoSchrapsessies"
												type="checkbox"
												checked={this.state.infoSchrapsessies}
												onChange={this.handleInputCheckBoxes}
											/>
											Schrapsessies
										</label>
									</div>

									<div className="ReCAPTCHA">
										<ReCAPTCHA
											sitekey="6LeX23UUAAAAAIFms45T3X4AztoMp35uOGU1bo71"
											onChange={() => this.setState({ 'NO-WALL-E': true })}
										/>
									</div>

								</div>
							</div>


							<input type="submit" value="Verstuur" className="btn" />
							<div className="message">{this.state.message}</div>

						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default Contact;
