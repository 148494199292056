import React from "react";

import logoVVAA from "../../images/logo-vvaa.png";
import logoAcademie from "../../images/logo-academie-voor-medisch-specialisten.png";
import logoAF from "../../images/logo-af.png";

import "./Partners.scss";

const Partners = () => (
	<div id="Partners" className="Blok">
		<div className="Container">
			<h2>Onze partners</h2>
			<div className="logos">
				<a href="https://www.academiemedischspecialisten.nl/" target="_blank" rel="noopener noreferrer">
					<img src={logoAcademie} alt="Logo Academie voor Medisch Specialisten" />
				</a>
				<a href="https://www.vvaa.nl/" target="_blank" rel="noopener noreferrer">
					<img src={logoVVAA} alt="Logo VVAA" />
				</a>
				<a href="https://www.argumentenfabriek.nl/" target="_blank" rel="noopener noreferrer">
					<img src={logoAF} alt="Logo De Argumentenfabriek" />
				</a>
			</div>

		</div>
	</div>
);

export default Partners;
