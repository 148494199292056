import React from 'react';

const LinkedIn = () => (
	<svg viewBox="0 0 44 44" className="icon linkedin">
		<path d="M15.5,13.63a1.54,1.54,0,0,1-1.66,1.6,1.55,1.55,0,0,1-1.57-1.6A1.59,1.59,0,0,1,13.9,12,1.55,1.55,0,0,1,15.5,13.63ZM12.59,32V17.66H15.2V32Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.5" />
    <path d="M19.53,21.54c0-1.48,0-2.7-.12-3.88h2.31L21.87,20h.06a5.27,5.27,0,0,1,4.74-2.7c2,0,5.07,1.19,5.07,6.1V32H29.13V23.73c0-2.31-.86-4.24-3.32-4.24a3.82,3.82,0,0,0-3.67,3.88V32H19.53Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.5" />
  </svg>
);

export default LinkedIn;
