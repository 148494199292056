import React from 'react';

const Hamburger = ({visibility}) => (
	<svg viewBox="0 0 30 30" className="icon hamburger">
		<polyline points="6,9 24,9" className="lijn1" strokeMiterlimit="10" ></polyline>
		<polyline points="6,15 24,15" className="lijn2" strokeMiterlimit="10" ></polyline>
		<polyline points="6,15 24,15" className="lijn3" strokeMiterlimit="10" ></polyline>
		<polyline points="6,21 24,21" className="lijn4" strokeMiterlimit="10" ></polyline>
	</svg>
);

export default Hamburger;

