import React from "react";

const Mail = props => (
	<svg viewBox="0 0 44 44" className="icon mail">
		<polygon
			points="25.86 0 2.14 0 14 8.18 25.86 0"
			fill="#fff"
			transform="translate(8 12)"
		/>
		<path
			d="M22,23.5a1.46,1.46,0,0,1-.85-.27L8,14.17V32H36V14.17L22.85,23.23A1.46,1.46,0,0,1,22,23.5Z"
			fill="#fff"
		/>
	</svg>
);

export default Mail;
