import React from "react";

import logoKNMT from "../../images/logo-knmt.png";
import logoDIAK from "../../images/logo-diakonessenhuis.png";
import logoReinier from "../../images/logo-reinierdegraaf.png";
import logoMSBD from "../../images/logo-msbd.png";
import logoNVK from "../../images/logo-nvk.png";
import logoVWS from "../../images/logo-vws.png";
import logoCRZ from "../../images/logo-crz.png";
import logoSRZ from "../../images/logo-srz.png";
import logoUMCG from "../../images/logo-umcg.png";

import "./Klanten.scss";

const Klanten = () => (
	<div id="Klanten" className="Blok">
		<div className="Container">
			<h2>Onze klanten</h2>
			<div className="logos">
				<a href="https://www.knmt.nl/" target="_blank" rel="noopener noreferrer">
					<img class="logoKNMT" src={logoKNMT} alt="Logo KNMT" />
				</a>
				<a href="https://reinierdegraaf.nl/" target="_blank" rel="noopener noreferrer">
					<img class="logoReinier" src={logoReinier} alt="Logo Reinier de Graaf" />
				</a>
				<a href="https://www.msbd.nl/" target="_blank" rel="noopener noreferrer">
					<img class="logoMSBD" src={logoMSBD} alt="Logo MSBD" />
				</a>
				<a href="https://www.diakonessenhuis.nl/" target="_blank" rel="noopener noreferrer">
					<img class="logoDIAK" src={logoDIAK} alt="Logo Diakonessenhuis" />
				</a>
				<a href="https://www.nvk.nl/" target="_blank" rel="noopener noreferrer">
					<img class="logoNVK" src={logoNVK} alt="Logo NVK" />
				</a>
				<a href="https://www.rijksoverheid.nl/ministeries/ministerie-van-volksgezondheid-welzijn-en-sport/" target="_blank" rel="noopener noreferrer">
					<img class="logoVWS" src={logoVWS} alt="Logo VWS" />
				</a>
				<a href="http://opzuid.nl/" target="_blank" rel="noopener noreferrer">
					<img class="logoCRZ" src={logoCRZ} alt="Logo CRZ" />
				</a>
				<a href="www.stichting-srz.nl" target="_blank" rel="noopener noreferrer">
					<img class="logoSRZ" src={logoSRZ} alt="Logo SRZ" />
				</a>
				<a href="https://www.umcg.nl/" target="_blank" rel="noopener noreferrer">
					<img class="logoUMCG" src={logoUMCG} alt="Logo umcg" />
				</a>

			</div>
		</div>
	</div>
);

export default Klanten;
