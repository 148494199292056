import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";


import Header from '../Header/Header';
import Intro from '../Intro/Intro';
import Diensten from '../Diensten/Diensten';
import Klanten from '../Klanten/Klanten';
import OverOns from '../OverOns/OverOns';
import Partners from '../Partners/Partners';
import Contact from '../Contact/Contact';

import Bestuur from '../Bestuur/Bestuur';
import Privacy from '../Privacy/Privacy';

import Footer from '../Footer/Footer';
import ShareButtons from '../ShareButtons/ShareButtons';

import './App.scss';

class App extends Component {

  render() {

  const FrontPage = props => (
    <div>
      <Intro />
      <Diensten />
      <Klanten />
      <OverOns />
      <Partners />
      <Contact />
    </div>
    );

    return (
      <Router>
        <div className="App">
          <Header />
          <Route exact path="/" component={FrontPage} />
          <Route exact path="/bestuur" component={Bestuur} />
          <Route exact path="/privacy" component={Privacy} />

          <Footer />
          <ShareButtons />
        </div>
      </Router>
    );
  }
}

export default App;
