import React, {Component} from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

import Hamburger from "../Icons/Hamburger";
import LogoOntregel from "../../images/logo-stichting-ordz.png";

import "./Header.scss";

class Header extends Component {

	state = { menuOpen: false }

	handleClick = () => {
		this.setState({ menuOpen: !this.state.menuOpen  })
	}

	closeMenu = () => {
		this.setState({ menuOpen: false  })
	}

	render() {

		const scrollWithOffset = (el, offset) => {
		  const elementPosition = el.offsetTop - offset;
		  window.scrollTo({
		    top: elementPosition,
		    left: 0,
		    behavior: "smooth"
		  });
		}

		const scroll = el => scrollWithOffset(el, 120);
		const location = {pathname: document.location.pathname + document.location.hash};

		return (
			<header className="Header">
				<div className="Container">
					<h1>Stichting (Ont)Regel de Zorg</h1>
					<div className={this.state.menuOpen ? "Hamburger open" : "Hamburger"} onClick={this.handleClick}><Hamburger /></div>
					<NavLink smooth to="/#Intro" activeClassName="active" scroll={scroll} location={location}>
						<img
							className="Logo"
							src={LogoOntregel}
							alt="Logo Stichtin (Ont)Regel de Zorg"
							onClick={this.closeMenu}
						/>
					</NavLink>
					<ul className={this.state.menuOpen ? "Navigation open" : "Navigation"} onClick={this.closeMenu}>
						<li>
							<NavLink smooth to="/#Diensten" activeClassName="active" scroll={scroll} location={location}>
								Diensten
							</NavLink>
						</li>

						<li>
							<NavLink smooth to="/#Klanten" activeClassName="active" scroll={scroll} location={location}>
								Klanten
							</NavLink>
						</li>

						<li>
							<NavLink smooth to="/#OverOns" activeClassName="active" scroll={scroll} location={location}>
								Over ons
							</NavLink>
						</li>

						<li>
							<NavLink smooth to="/#Partners" activeClassName="active" scroll={scroll} location={location}>
								Partners
							</NavLink>
						</li>

						<li>
							<NavLink smooth to="/#Contact" activeClassName="active" scroll={scroll} location={location}>
								Contact
							</NavLink>
						</li>
					</ul>
				</div>
			</header>
		);
	}
};

export default Header;
