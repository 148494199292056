import React from "react";

import Twitter from "../Icons/Twitter";
import Facebook from "../Icons/Facebook";
import LinkedIn from "../Icons/LinkedIn";


import './ShareButtons.scss';


const ShareButtons = () => {

		const clickHandler = (event) => {
			//event.preventDefault();
			//this.setState({message: 'In de testversie is het delen van de app nog niet mogelijk.'})
		}

		const url = "https://www.stichtingontregeldezorg.nl";
		const title = "Stichting (Ont)Regel de Zorg";
		const text = "Meer tijd, meer autonomie, minder regels, minder bureaucratie";

		return (
		<div className="ShareButtons">

				<a
						onClick={clickHandler}
						href={'https://facebook.com/sharer/sharer.php?u=' + url}
						target='_blank'
						rel='noopener noreferrer'
						aria-label='Deel deze pagina op Facebook.'>
				    <Facebook/>
				</a>

				<a
						onClick={clickHandler}
						href={'https://twitter.com/intent/tweet/?text=' + encodeURIComponent(text) + ' %23ordz&url=' + url}
						target='_blank'
						rel='noopener noreferrer'
						aria-label='Deel deze pagina via Twitter.'>
						<Twitter />
				</a>

				<a
						onClick={clickHandler}
						href={'https://www.linkedin.com/shareArticle?mini=true&amp;url=' + url + '&amp;title=' + encodeURIComponent(title) + '&amp;summary=' + encodeURIComponent(text) }
						target='_blank'
						rel='noopener noreferrer'
						aria-label='Deel deze pagina via LinkedIn'>
				  		<LinkedIn />
		  	 </a>


			</div>
	);
	}

export default ShareButtons;

