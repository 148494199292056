import React from "react";

import "./Privacy.scss";

const Privacy = () => (
	<div id="Privacy" className="Blok">
		<div className="Overlay" />
		<div className="Container">
			<h2>Verklaring privacy en persoonsgegevens</h2>

			<div className="Content">
				<p>
					De privacy van onze klanten, collega’s en partners vinden we erg
					belangrijk. Om zaken te kunnen doen, delen jullie je persoonsgegevens
					met ons. We gaan daar vanzelfsprekend zorgvuldig mee om, conform de
					eisen die de Europese privacy verordening (AVG) ons stelt. Zie voor
					meer betrouwbare informatie over de AVG de  <a
						href="https://www.autoriteitpersoonsgegevens.nl/"
						target="_blank"
						rel="noopener noreferrer">
						website van de Autoriteit Persoonsgegevens
					</a>.
				</p>

				<h4>Webwinkel</h4>
				<p>
					Mocht je een contract met ons hebben gesloten of iets hebben gekocht
					in onze webwinkel, dan slaan we je gegevens op in een van onze
					beveiligde databases. We geven je informatie nooit door aan derden.
				</p>

				<h4>Meer weten, wijzigen of verwijderen</h4>
				<p>
					Wil je weten wat we van je weten, wil je je persoonsgegevens inkijken,
					of wil je gegevens laten wijzigen of verwijderen uit ons systeem? Geen
					probleem. Stuur dan een mail aan <a href="mailto:info@stichtingontregeldezorg.nl">info@stichtingontregeldezorg.nl</a>.
				</p>

				<h4>Google Analytics</h4>
				<p>
					Om meer te leren over wat wel en niet werkt op onze websites, maken we
					gebruik van Google Analytics, een analysegereedschap van Google.
					Google Analytics maakt gebruik van zogenaamde "cookies"
					(tekstbestandjes die op je computer worden geplaatst) om zo het
					gebruik van onze websites te kunnen analyseren.
				</p>

				<p>
					Als je niet wilt dat Google cookies opslaat kun je dit uitzetten in de
					instellingen van je browser. Ook kun je bij Google uitzetten dat ze
					gebruik maken van de gegevens die je door hun cookies hebt
					gegenereerd. Dat door een <a href="http://tools.google.com/dlpage/gaoptout?hl=nl" target="_blank" rel="noopener noreferrer">opt-out browser add-on te installeren</a>.
				</p>

				<p>
					Meer informatie over de gebruiksvoorwaarden en gegevensbescherming
					vind je bij de voorwaarden van Google Analytics of bij het privacy
					overzicht van Google Analytics.
				</p>

				<h4>Wat als er iets mis gaat?</h4>
				<p>
					Als gezegd: we gaan zeer zorgvuldig om met alle persoonsgegevens die
					jullie aan ons toevertrouwen. Mocht er onverhoopt toch iets fout gaan,
					dan zullen we dat melden. De Autoriteit Persoonsgegevens heeft
					hiervoor in 2015 de ‘beleidsregels meldplicht datalekken’ opgesteld.
					Daar houden we ons aan.
				</p>

				<h4>Over deze verklaring</h4>
				<p>
					Dit is de Privacy-verklaring van Stichting (Ont)Regel de Zorg. Heb je
					vragen over deze verklaring? Schroom niet en mail naar
					<a href="mailto:info@stichtingontregeldezorg.nl">info@stichtingontregeldezorg.nl</a>.
				</p>
			</div>
		</div>
	</div>
);

export default Privacy;
