import React from 'react';

import './Intro.scss';

const Intro = () =>
(
 <div id="Intro" className="Blok">
  <div className="BlokOverlay">
	  <div className="Container">
	    <h1>Meer tijd, meer autonomie, minder regels, minder bureaucratie</h1>
	    <p>Stichting (Ont)Regel de Zorg werkt aan betere zorg voor patiënt en zorgverlener doordat we merkbare tijdswinst boeken door het schrappen van regels en administratieve rompslomp.</p>
	    <p>Ons doel is de administratieve lasten drastisch te verminderen, zowel op landelijk niveau als in instellingen. We helpen zorgverleners om hierbij zelf het heft weer in eigen hand te nemen. We willen bijdragen aan de hiervoor benodigde mentaliteits- en cultuurverandering. Meer zorg, minder administratief gedoe. Dat is ons streven.</p>
	  </div>
  </div>
</div>
)

export default Intro;
