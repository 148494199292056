import React from "react";
import { Link } from "react-scroll";

import Pijl from "../Icons/Pijl";
import "./Card.scss";

const Card = props => (
	<div className="Card">
		<h4>{props.title}</h4>
		<img src={props.image} alt="diensten"/>
		<div className="Content">
			{props.children}
		</div>
		<div className="CardFooter">
				<Link
					activeClass="active"
					to="Contact"
					smooth={true}
					duration={500}
					offset={-120}
					spy={true}
				>
				<button className="btn">Meer informatie<Pijl /></button>
			</Link>
		</div>
	</div>
);

export default Card;
