import React from "react";

import Card from "../Card/Card";
import picIntro from "../../images/image-pen.jpg";
import picTrechter from "../../images/image-trechter.png";

import "./Diensten.scss";

const Diensten = () => (
	<div id="Diensten" className="Blok">
	  <div className="Container">
			<h2>Onze diensten</h2>
			<div className="Cards">
				<Card title="(Ont)regel de zorg-trainingen" image={picTrechter}>
					<p>Hoe moet je beginnen als je zelf aan de slag wilt met het terugdringen van de bureaucratie in jouw instelling?
						We helpen je graag op weg met een van onze trainingen.</p>

					<p>Ben je medisch specialist? Verpleegkundige? Of manager? Of will je met je hele team aan het werk? Het kan allemaal.</p> 
						<p>We hebben zelfs een ‘introductietraining’ van een halve dag. Daarna kun je direct aan de slag met het concreet terugdringen van de regeldruk in je eigen instelling.</p>
					<p>Deze training is CRKBO-geregistreerd en vrijgesteld van BTW.</p>
				</Card>
				<Card title="Schraptrajecten" image={picIntro}>
				<p>Schraptrajecten doen we zowel binnen zorgorganisaties als beroepsgroepbreed. We inventariseren de lastendruk, formuleren samen met belanghebbenden de schrapsuggesties en maken concrete actieplannen, waarmee zorgverleners direct aan de slag kunnen.</p>
					<ul>
						<li>Lastendruk in kaart brengen</li>
						<li>Schrapsessies</li>
						<li>Begeleiding bij implementatie</li>
						<li>Begeleiding bij cultuurverandering</li>
					</ul>
				</Card>
			</div>
		</div>
	</div>
);

export default Diensten;
